import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "'Max', sans-serif",
    body: "'Noto Sans', sans-serif",
  },
  colors: {
    ahuaBlue: {
      0: "#E6F2FA",
      5: "#D2E9F9",
      10: "#BCDFF8",
      20: "#82C5F4",
      30: "#55AEED",
      40: "#2F95E5",
      50: "#137ED9",
      60: "#0068CA",
      70: "#0058B7",
      80: "#0047A0",
      90: "#003686",
      100: "#00276B",
    },
    ahuaOrange: {
      0: "#FAEFE6",
      5: "#FAE3D2",
      10: "#FCD6BB",
      20: "#FFCAA3",
      30: "#FAA86E",
      40: "#F58433",
      50: "#E86B13",
      60: "#E05D00",
      70: "#D15100",
      80: "#C24300",
      90: "#A32F00",
      100: "#7A1A00",
    },
  },
})

export default theme
